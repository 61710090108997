@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Chakra Petch', sans-serif;
  box-sizing: border-box;
  color: white;
}

/* Hide Google reCaptcha */
.grecaptcha-badge { visibility: hidden; }

/* CSS FOR SCROLL */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3ed5dd; /* Light blue scrollbar thumb */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #3ed5dd; /* Darker blue on hover */
}

.nonefordesktab {
  display: none;
}

.hovercss:hover span {
  color: #3ed5dd;
}

/* Targeting webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #00040F; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(62 213 221); /* Scrollbar thumb (the draggable part) */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  /* border: 3px solid #f1f1f1; Padding around the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(62 213 221); /* Thumb color on hover */
}

::selection {
  background-color: rgb(62 213 221); /* Background color when text is selected */
  color: #000; /* Text color when selected */
}

/* For Firefox */
::-moz-selection {
  background-color: rgb(62 213 221); /* Background color for Firefox */
  color: #000; /* Text color for Firefox */
}

/* CSS FOR CONIC GRADIENT */
:root {
  --conic-size: 150%; /* Controls the size of the rotating border */
  --border-color: rgba(168, 239, 255, 1); /* Main border color */
  --background-color: #000; /* Background color of the button */
  --border-radius: 8px; /* Border radius of the button */
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes opacityChange {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.conic {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(-1 * var(--conic-size) / 4); /* Center the border effect */
    top: calc(-1 * var(--conic-size) / 4);
    width: var(--conic-size);
    height: var(--conic-size);
    background-color: var(--background-color);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(transparent, var(--border-color), transparent 20%);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: var(--background-color);
    border-radius: var(--border-radius);
  }
}

/* CSS FOR SCROLL ANIMATIONS */
@keyframes slideUp {
  from {
    transform: translateY(70px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUp 0.7s ease-out forwards;
}

.animate-slide-left {
  animation: slideLeft 0.7s ease-out forwards;
}

.animate-slide-right {
  animation: slideRight 0.7s ease-out forwards;
}

/* CHARACTER DISPLAY ONE BY ONE */
.animated-character {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
